import {createSelector} from 'reselect';
import { flatten, uniqBy } from 'lodash';

const data = state => state.data;

export const getAuthorList = createSelector([data], (data) => {
  if (data.length === 0) {
    return [];
  }

  let allAuthors = data.map(e => e.Author);

  let uniqueAuthors = uniqBy(flatten(allAuthors), 'Id');

  return [
    {
      Id: 0,
      Value: 'Author: All'
    },
    ...uniqueAuthors
  ]
})