import { SET_PAGE_SIZE } from '../enums';

function combineIdsForDuplicateNames(arr) {
  const nameMap = {};

  arr.forEach(item => {
    if (!nameMap[item.Value]) {
      nameMap[item.Value] = { Id: item.Id, Value: item.Value };
    } else {
      nameMap[item.Value].Id += `,${item.Id}`;
    }
  });
  return Object.values(nameMap);
}

export const communityOptions = (state = window.cls.communitiesOptions) => {
  return state;
}
export const productOptions = (state = window.cls.productsOptions) => {
  return state;
}
export const topicOptions = (state = combineIdsForDuplicateNames(window.cls.topicsOptions)) => {
  // topic options are unique in that different meanings are ascribed to the same words
  // ie Settlement (an insight landing / category page) and Settlement (an insight sub-category)
  // The server will deliver all topics, so we'll end up with repeating words. The list needs to be combnined.
  return state;
}
export const assetTypeOptions = (state = window.cls.assetTypesOptions) => {
  return state;
}
export const eventTypeOptions = (state = window.cls.eventTypesOptions) => {
  return state;
}

export const seriesOptions = (state = window.cls.seriesOptions) => {
  return state;
}

export const pageSize = (state = 12, action) => {
  switch (action.type) {
    case SET_PAGE_SIZE:
      return state + 12;
    default:
      return state;
  }
};