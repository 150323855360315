import {createSelector} from 'reselect';
import {filter, take, get, find, intersection } from 'lodash';

const communitiesFilter = state => state.communitiesFilter;
const topicsFilter = state => state.topicsFilter;
const assetTypeFilter = state => state.assetTypeFilter;
const productsFilter = state => state.productsFilter;
const data = state => state.data;
const pageSize = state => state.pageSize;
const eventTypeFilter = state => state.eventTypeFilter;
const eventTypeOptions = state => state.eventTypeOptions;
const textSearchResults = state => state.textSearchResults;
const textSearch = state => state.textSearch;
const authorFilter = state => state.authorFilter;
const seriesFilter = state => state.seriesFilter;

const filterById = (data, propName, filterId) => {
  return filter(data, e => e[propName].includes(parseInt(filterId, 10)));
}

const getItemsByCommunity = createSelector([data, communitiesFilter], (data, filterId) => {
  if (parseInt(filterId, 10) === 0) {
    return data;
  }
  return filterById(data, 'Communities', filterId);
})

const getItemsByProduct = createSelector([getItemsByCommunity, productsFilter], (data, filterId) => {
  if (parseInt(filterId, 10) === 0) {
    return data;
  }
  return filterById(data, 'Products', filterId);
})

const getItemsByTopic = createSelector([getItemsByProduct, topicsFilter], (data, filterId) => {
  if (parseInt(filterId, 10) === 0) {
    return data;
  }

  if (Number.isInteger(filterId)) {
    return filter(data, e => intersection(e.Topics, [filterId]).length > 0);  
  }

  //take the filterId, split on ',' filter on the intersection of data[index].Topics and filterId 
  const topicFilters = filterId.split(',').map(t => parseInt(t, 10));
  return filter(data, e => intersection(e.Topics, topicFilters).length > 0);
})

const getItemsByAssetType = createSelector([getItemsByTopic, assetTypeFilter], (data, filterId) => {
  if (parseInt(filterId, 10) === 0) {
    return data;
  }
  return filter(data, {'AssetType': parseInt(filterId, 10)});
})

const getItemsByEventType = createSelector([getItemsByAssetType, eventTypeFilter, eventTypeOptions], (data, filterId, eventOptions) => {

//   [
//     {
//         "Id": 0,
//         "Value": "Event type: All"
//     },
//     {
//         "Id": 1,
//         "Value": "Proprietary event"
//     },
//     {
//         "Id": 2,
//         "Value": "Partner event"
//     }
// ]
  //the event types is a magic string in the data
  //the drop down has values (0,1,2) so we need to convert the selected value back into a string, then filter the data

  if (parseInt(filterId, 10) === 0) {
    return data;
  }

  let eventFilterString = get(find(eventOptions, {Id: parseInt(filterId, 10)}), 'Value', 0);

  if (eventFilterString === 0) {
    return data;
  }

  return filter(data, {'EventType': eventFilterString});
})

/**
 * The text search is done on the server. Search results will be a set of IDs. This function will filter insights
 * on the page by these IDs.
 */
const getItemsBySearchResult = createSelector([textSearchResults, getItemsByEventType, textSearch], (results, data, textSearch) => {
  
  if (textSearch.length === 0) {
    return data;
  }

  return data.filter(obj => results.includes(obj.Id));
});

const getItemsByAuthor = createSelector([getItemsBySearchResult, authorFilter], (data, authorFilter) => {
  if (parseInt(authorFilter, 10) === 0) {
    return data;
  }

  return filter(data, e => find(e.Author, author => author.Id === parseInt(authorFilter, 10)));;
})

const getItemsBySeries = createSelector([getItemsByAuthor, seriesFilter], (data, seriesFilter) => {
  if (parseInt(seriesFilter, 10) === 0) {
    return data;
  }

  return filter(data, e => e.Series === parseInt(seriesFilter, 10));
})

export const getFilteredItems = createSelector([getItemsBySeries, pageSize], (data, pageSize) => {
  return take(data, pageSize);
});
