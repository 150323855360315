import React from 'react';

//3 possible link types exist:
//document isn't tracked - return <a href="/path/to/document"></a>
//document is tracked and there's a cookie - return <a href="javascript:{}" onclick="add the documentID to the publication document download form and submit the form"></a>
//document is tracked and there's no cookie - return <a href="javascript:{}" onclick="openModal(this);" data-documentname="@documentName" data-documentid="@documentDownloadId"></a>
//This component is only used for publications

const canDownload = () => {
  if (document.getElementById('can-download')) {
    return document.getElementById('can-download').value === 'true' ? true : false;
  }
  return false;
}

export const DocumentLink = ({
  DocumentDownload,
  DocumentDownloadName,
  DocumentDownloadUrl,
  DocumentIsTracked,
  ItemUrl,
  Title,
  UrlOverride
}) => {

  const getTrackedDocument = () => {
    window.$('#publications-document-id').val(DocumentDownload);
    document.getElementById('publications-download-form').submit();
  }

  const openModal = () => {
    window.openModal({
      dataset: {
        documentid: DocumentDownload,
        documentname: DocumentDownloadName
      }
    });
  }

  const createMarkup = (content) => {
    return { __html: content };
  }

  return (
    <>
      {UrlOverride.length > 0 && <a href={UrlOverride} className="stretched-link hover:underline" dangerouslySetInnerHTML={createMarkup(Title)}></a>}

      {UrlOverride.length === 0 && 
        <>
          {!DocumentIsTracked && DocumentDownloadUrl.length === 0 && <a href={ItemUrl} className="stretched-link hover:underline" dangerouslySetInnerHTML={createMarkup(Title)}></a>}

          {!DocumentIsTracked && DocumentDownloadUrl.length > 0 && <a href={DocumentDownloadUrl} target="_blank" rel="noreferrer" className="stretched-link hover:underline" dangerouslySetInnerHTML={createMarkup(Title)}></a>}

          {DocumentIsTracked && canDownload() &&
            <div className="stretched-link cursor-pointer hover:underline" onClick={getTrackedDocument} dangerouslySetInnerHTML={createMarkup(Title)}></div>}

          {DocumentIsTracked && !canDownload() &&
            <div className="stretched-link cursor-pointer hover:underline" onClick={openModal} dangerouslySetInnerHTML={createMarkup(Title)}></div>}
        </>}
    </>
  )
}