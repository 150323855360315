import {
  SET_COMMUNITY, SET_ASSET, SET_PRODUCT, SET_TOPIC,
  URL_HASH_KEY_COMMUNITY,
  URL_HASH_KEY_PRODUCT,
  URL_HASH_KEY_TOPIC,
  URL_HASH_KEY_ASSET,
  URL_HASH_KEY_EVENT,
  SET_EVENT,
  SET_SEARCH,
  URL_HASH_KEY_SEARCH,
  SET_TEXT_SEARCH_RESULTS,
  SET_AUTHOR,
  URL_HASH_KEY_AUTHOR,
  URL_HASH_KEY_SERIES,
  SET_SERIES
} from '../enums';
import { find, kebabCase, get, uniqBy, flatten } from 'lodash';
//'#community=1134&product=1148&topic=1299&asset=1923'

//leave this in case we want to revert to ID's in the url
// const getFilterId = (filterNameIdPair) => {
//   return isNaN(parseInt(filterNameIdPair.replace(/\D/g, ''), 10)) ? 0 : parseInt(filterNameIdPair.replace(/\D/g, ''), 10);
// }

const getAuthorsFromList = () => {
  let allAuthors = window.listItems.map(e => e.Author);

  let uniqueAuthors = uniqBy(flatten(allAuthors), 'Id');

  return [
    {
      Id: 0,
      Value: 'Author: Any'
    },
    ...uniqueAuthors
  ]
}

const getFilterIdByValue = (filterNamePair, filterOptionsKey) => {
  if (!filterNamePair) {
    return 0;
  }

  const { communitiesOptions, productsOptions, topicsOptions, assetTypesOptions, eventTypeOptions, seriesOptions } = window.cls;
  const authorOptions = getAuthorsFromList();
  let filterValue = filterNamePair.split('=')[1];
  let option;

  switch (filterOptionsKey) {
    case URL_HASH_KEY_COMMUNITY:
      option = find(communitiesOptions, e => kebabCase(e.Value) === filterValue);
      break;
    case URL_HASH_KEY_PRODUCT:
      option = find(productsOptions, e => kebabCase(e.Value) === filterValue);
      break;
    case URL_HASH_KEY_TOPIC:
      option = find(topicsOptions, e => kebabCase(e.Value) === filterValue);
      break;
    case URL_HASH_KEY_ASSET:
      option = find(assetTypesOptions, e => kebabCase(e.Value) === filterValue);
      break;
    case URL_HASH_KEY_EVENT:
      option = find(eventTypeOptions, e => kebabCase(e.Value) === filterValue);
      break;
    case URL_HASH_KEY_AUTHOR:
      option = find(authorOptions, e => kebabCase(e.Value) === filterValue);
      break;
    case URL_HASH_KEY_SERIES:
      option = find(seriesOptions, e => kebabCase(e.Value) === filterValue);
      break;
    default:
      break;
  }

  return get(option, 'Id', 0);
}

const getFilterId = (filterNamePair, filterOptionsKey) => {
  return parseInt(getFilterIdByValue(filterNamePair, filterOptionsKey));
}

const getUrlFilter = (filterType) => {
  let filterId = 0;

  if (window.location.hash.length > 0) {
    let urlHash = window.location.hash.replace('#', '').split('&');
    switch (filterType) {
      case SET_COMMUNITY:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_COMMUNITY)), URL_HASH_KEY_COMMUNITY);
        break;
      case SET_PRODUCT:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_PRODUCT)), URL_HASH_KEY_PRODUCT);
        break;
      case SET_TOPIC:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_TOPIC)), URL_HASH_KEY_TOPIC);
        break;
      case SET_ASSET:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_ASSET)), URL_HASH_KEY_ASSET);
        break;
      case SET_EVENT:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_EVENT)), URL_HASH_KEY_EVENT);
        break;
      case SET_SEARCH:
        break;
      case SET_AUTHOR:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_AUTHOR)), URL_HASH_KEY_AUTHOR);
        break;
      case SET_SERIES:
        filterId = getFilterId(find(urlHash, e => e.includes(URL_HASH_KEY_SERIES)), URL_HASH_KEY_SERIES);
        break;
      default:
        break;
    }
  }

  return filterId;
}

const getKeywordsFromUrl = () => {
  let urlHash = window.location.hash.replace('#', '').split('&');
  const keyValue = find(urlHash, e => e.includes(URL_HASH_KEY_SEARCH));
  if (keyValue) {
    return keyValue.split('=')[1];
  } else {
    return '';
  }
}

export const communitiesFilter = (state = getUrlFilter(SET_COMMUNITY), action) => {
  switch (action.type) {
    case SET_COMMUNITY:
      return action.obj;
    default:
      return state;
  }
}
export const productsFilter = (state = getUrlFilter(SET_PRODUCT), action) => {
  switch (action.type) {
    case SET_PRODUCT:
      return action.obj;
    default:
      return state;
  }
}
export const topicsFilter = (state = getUrlFilter(SET_TOPIC), action) => {
  switch (action.type) {
    case SET_TOPIC:
      return action.obj;
    default:
      return state;
  }
}
export const assetTypeFilter = (state = getUrlFilter(SET_ASSET), action) => {
  switch (action.type) {
    case SET_ASSET:
      return action.obj;
    default:
      return state;
  }
}

export const eventTypeFilter = (state = getUrlFilter(SET_EVENT), action) => {
  switch (action.type) {
    case SET_EVENT:
      return action.obj;
    default:
      return state;
  }
}

export const textSearch = (state = getKeywordsFromUrl(), action) => {
  switch (action.type) {
    case SET_SEARCH:
      return action.obj;
    default:
      return state;
  }
}

export const authorFilter = (state = getUrlFilter(SET_AUTHOR), action) => {
  switch (action.type) {
    case SET_AUTHOR:
      return action.obj;
    default:
      return state;
  }
}

export const seriesFilter = (state = getUrlFilter(SET_SERIES), action) => {
  switch (action.type) {
    case SET_SERIES:
      return action.obj;
    default:
      return state;
  }
}

/**
 * The (server side) text search will return a list of IDs, put those IDs here
 * so they can be used by a selector
 * @param {*} state an empty array of number
 * @param {*} action 
 */
export const textSearchResults = (state = [], action) => {
  switch (action.type) {
    case SET_TEXT_SEARCH_RESULTS:
      return action.obj;
    default:
      return state;
  }
}

export const selectedOptions = (state = {
  SET_COMMUNITY: getUrlFilter(SET_COMMUNITY),
  SET_PRODUCT: getUrlFilter(SET_PRODUCT),
  SET_TOPIC: getUrlFilter(SET_TOPIC),
  SET_ASSET: getUrlFilter(SET_ASSET),
  SET_SEARCH: getUrlFilter(SET_SEARCH),
  SET_AUTHOR: getUrlFilter(SET_AUTHOR),
  SET_SERIES: getUrlFilter(SET_SERIES)
}, action) => {
  if (action.obj && action.type !== SET_TEXT_SEARCH_RESULTS) {
    return {
      ...state,
      [action.type]: isNaN(parseInt(action.obj, 10)) ? '' : parseInt(action.obj, 10)
    }
  } else {
    return state;
  }
}

export const enabledFilters = (state = {
  SET_SEARCH : window.showTextSearch ? true : undefined,
  SET_COMMUNITY : window.showCommunitiesFilter ? true : undefined,
  SET_PRODUCT : window.ShowProductFilter ? true : undefined,
  SET_TOPIC : window.showTopicFilter ? true : undefined,
  SET_ASSET : window.showTypeFilter ? true : undefined,
  SET_EVENT : window.showEventFilter ? true : undefined,
  SET_AUTHOR : window.showAuthorFilter ? true : undefined,
  SET_SERIES : window.showInsightSeriesFilter ? true : undefined
}) => {
  return JSON.parse(JSON.stringify(state));
}