import React from 'react';
import { LIST_TYPE_INSIGHTS } from '../../enums';


const getPadding = () => {
  if (window.listType === LIST_TYPE_INSIGHTS) {
    return 'px-4';
  } else {
    return 'pr-15 xl:pl-12';
  }
}
//{options: Array(7), optionType: 'comunity', onOptionSelected: ƒ}
export const Filter = ({options, onOptionSelected, optionType, selectedValue}) => {

  return (
    <>
      {options.length === 0 && <span></span>}
      {options.length > 0 && 
      <select className={`product-filter rounded-full w-full border-opacity-100 mb-4 ${getPadding()} ${window.cls.filtersBgColour} lg:mb-0`} name="" id="" 
        onChange={(e) => onOptionSelected(e.target.value, optionType)} value={selectedValue}>
        {options.map(opt => <option key={opt.Id} value={opt.Id}>{opt.Value}</option>)}
      </select>}
    </>
  )
}