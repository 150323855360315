//option types
export const COMMUNITY = 'comunity';
export const PRODUCT = 'product';
export const TOPIC = 'topic';
export const ASSET = 'asset';
export const EVENT = 'event';
export const SEARCH = 'search' // text search
export const AUTHOR = 'author';
export const SERIES = 'series';

//filters
export const SET_COMMUNITY = 'SET_COMMUNITY';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_ASSET = 'SET_ASSET';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_EVENT = 'SET_EVENT';
export const SET_TEXT_SEARCH_RESULTS = 'SET_TEXT_SEARCH_RESULTS';
export const SET_AUTHOR = 'SET_AUTHOR';
export const SET_SERIES = 'SET_SERIES';
/**
 * text search term
 */
export const SET_SEARCH = 'SET_SEARCH'; 

//window.location.hash keys
export const URL_HASH_KEY_COMMUNITY = 'community';
export const URL_HASH_KEY_PRODUCT = 'product';
export const URL_HASH_KEY_TOPIC = 'topic';
export const URL_HASH_KEY_ASSET = 'asset';
export const URL_HASH_KEY_EVENT = 'event';
export const URL_HASH_KEY_SEARCH = 'search';
export const URL_HASH_KEY_AUTHOR = 'author';
export const URL_HASH_KEY_SERIES = 'series';

//list type
export const LIST_TYPE_INSIGHTS = 'Insights';