import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { SET_TEXT_SEARCH_RESULTS, SET_SEARCH } from '../../enums';
import {useDebounce} from 'use-debounce';

const SearchBox = ({ dispatch, initialKeywords, setLoading }) => {

  const [keywords, setKeywords] = useState(initialKeywords);
  const [debouncedKeywords] = useDebounce(keywords, 500);

  useEffect(() => {

    async function doSearch(searchTerm) {
      if (searchTerm.length <= 3) {
        return;
      }
  
      dispatch({ type: SET_SEARCH, obj: searchTerm });
      
      let url = `/api/InsightsSearch/GetInsights?keywords=${searchTerm}`;
  
      if (window.location.href.includes('localhost')) {
        url = `https://localhost:44337/api/InsightsSearch/GetInsights?keywords=${searchTerm}`;
      }
  
      setLoading(true);
      let result = await axios({
        method: 'GET',
        url
      }).catch(e => console.log('Search failed. ', e));
      
      if (result && result.data) {
        dispatch({ type: SET_TEXT_SEARCH_RESULTS, obj: result.data });
      }
      setLoading(false);
    }

    doSearch(debouncedKeywords);
  }, [debouncedKeywords, dispatch, setLoading])

  const handleInputChange = (event) => {
    const { value } = event.target;
    setKeywords(value);
  };

  // as well as clearing the local keywords we need to clear them in state
  // and also clear any results from the textSearchResults array 
  const clearSearch = () => {
    setKeywords('');
    dispatch({ type: SET_SEARCH, obj: '' });
    dispatch({ type: SET_TEXT_SEARCH_RESULTS, obj: [] });
  }

  return (
    <>
      <div className='flex w-full mb-4 lg:mb-0'>
        <div className='relative w-full'>
          <input type="text" className='rounded-full pr-12 w-full' placeholder='Search...' value={keywords} onChange={handleInputChange} />
          <div className="absolute" style={{ top: 8, right: 15, cursor: 'pointer' }} onClick={clearSearch}>
            <i className="fas fa-times"></i>
          </div>
          {/* <div className="absolute" style={{ top: 8, right: 20, cursor: 'pointer' }} onClick={() => doSearch()}>
            <i className="fas fa-search"></i>
          </div> */}
        </div>
      </div>

      {/* <div className='flex w-full'>
        <div className="inline-block ml-2 cursor-pointer" onClick={() => doSearch()}>
          <div className="button button-box relative rounded-full">
            <span>
              Search
            </span>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default connect()(SearchBox)