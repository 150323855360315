import { Filter } from './components/filters/filter';
import { COMMUNITY, PRODUCT, ASSET, TOPIC, EVENT, AUTHOR, SET_AUTHOR, SERIES } from './enums';
import { ListItem } from './components/blocks/listItem';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';
import { SET_COMMUNITY, SET_TOPIC, SET_PRODUCT, SET_ASSET, SET_PAGE_SIZE, SET_EVENT, LIST_TYPE_INSIGHTS, SET_SERIES } from './enums';
import { getFilteredItems } from './selectors/selectItems';
import useEffectAllDepsChange from './effects/useEffectAllDepsChange';
import { useEffect, useRef, useState } from 'react';
import { getLocationHash } from './helpers/locationHash';
import useHash from './effects/useHash';
import SearchBox from './components/filters/searchBox';
import { getAuthorList } from './selectors/authorListSelector';
import {find} from 'lodash';

const breakpointColumnsObj = {
  default: window.listType === LIST_TYPE_INSIGHTS ? 3 : 4,
  1100: 3,
  700: 2,
  500: 1
};

function App({
  data,
  communityOptions,
  productOptions,
  topicOptions,
  assetTypeOptions,
  dispatch,
  pageSize,
  selectedOptions,
  communitiesFilter,
  productsFilter,
  topicsFilter,
  assetTypeFilter,
  eventTypeFilter,
  eventTypeOptions,
  textSearch,
  authorOptions,
  seriesOptions,
  enabledFilters
}) {

  const [hash, setHash] = useHash();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const loader = useRef(null)

  // useEffectAllDepsChange(() => {
  //   //scroll the page a bit when new items are loaded
  //   if (pageSize > 12) {
  //     //find the pageSize + 1 item in data
  //     const target = data[pageSize - 12];
  //     if (target) {
  //       const yOffset = -120;
  //       const element = document.getElementById(target.Id);
  //       const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //       window.scrollTo({ top: y, behavior: 'smooth' });
  //     }
  //   }
  // }, [pageSize, data])

  useEffect(() => {
    if (hash.includes('reload=1')) {
      //set this so we can reload the page when only the url hash changes - eg:
      //http://localhost:3000/#topic=cls-market-data&asset=report (via the dropdowns) to http://localhost:3000/#community=non-bank-financial-instituions&product=cls-market-data (via clicking a link)
      setHash(hash.replace('&reload=1', ''));
      window.location.reload();
    }
  }, [hash, setHash])

  useEffect(() => {
    const values = Object.values(selectedOptions);
    if (values.length > 0) {
      //set the location hash whenever the options change
      window.location.hash = getLocationHash(selectedOptions, {
        SET_COMMUNITY: communityOptions,
        SET_PRODUCT: productOptions,
        SET_TOPIC: topicOptions,
        SET_ASSET: assetTypeOptions,
        SET_EVENT: eventTypeOptions,
        SET_SEARCH: textSearch,
        SET_AUTHOR: authorOptions,
        SET_SERIES: seriesOptions
      }, enabledFilters);
    } else {
      window.location.hash = '';
    }
  }, [selectedOptions,
    communityOptions,
    productOptions,
    topicOptions,
    assetTypeOptions,
    eventTypeOptions,
    textSearch,
    authorOptions,
    seriesOptions,
    enabledFilters]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }
    //init an observer and use the (former) load more button as the target
    const observer = new IntersectionObserver(handleObserver, options)
    if (loader.current) {
      observer.observe(loader.current)
    }
  }, [])

  useEffect(() => {
    //watch the page state above (changed by handleObserver)
    //and dispatch an increase in pageSize to load 12 blocks into the list
    const timer = setTimeout(() => {
      if (page > 0) {
        dispatch({ type: SET_PAGE_SIZE });
      }
    }, 300);
    return () => clearTimeout(timer);
    
  }, [page, dispatch]);


  useEffect(() => {
    if (!isNaN(parseInt(window.insightsCategoryLandingPageId, 10))) {

      // We need to take into account 'Topic' has values like "1204,5196" which is the landing page id & insight subcategories.
      // Search topicOptions for values that include window.insightsCategoryLandingPageId eg {Id: '1204,5196', Value: 'The FX ecosystem'} and 
      // use the Id from there in the SET_TOPIC dispatch 
      let topicListItem = find(topicOptions, e => e.Id.toString().includes(window.insightsCategoryLandingPageId))

      dispatch({type: SET_TOPIC, obj: topicListItem.Id});
      window.insightsCategoryLandingPageId = '';
    }
  });

  const optionChanged = (val, optionType) => {
    //update the option type for each of the dropdowns
    switch (optionType) {
      case COMMUNITY:
        dispatch({ type: SET_COMMUNITY, obj: val });
        break;
      case PRODUCT:
        dispatch({ type: SET_PRODUCT, obj: val });
        break;
      case TOPIC:
        dispatch({ type: SET_TOPIC, obj: val });
        break;
      case ASSET:
        dispatch({ type: SET_ASSET, obj: val });
        break;
      case EVENT:
        dispatch({ type: SET_EVENT, obj: val });
        break;
      case AUTHOR:
        dispatch({type: SET_AUTHOR, obj: val});
        break;
      case SERIES:
        dispatch({type: SET_SERIES, obj: val});
        break;
      default:
        break;
    }
  }

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      //Check the target (ref={loader}) is in the viewable area
      //and change the page variable above to a new number thus
      //triggering the useEffect that's watching [page].
      //Using dispatch does work but eslint throws an error so we'll
      //maintain 0 deps for this function and put the dispatch in a useEffect above
      setPage(new Date().getTime());
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <>
      {window.showFilters &&
        <div className='lg:flex gap-2 mb-10'>
          {window.showTextSearch && <SearchBox initialKeywords={textSearch} setLoading={setLoading} />}
          {window.showCommunitiesFilter && <Filter options={communityOptions} onOptionSelected={optionChanged} optionType={COMMUNITY} selectedValue={communitiesFilter} />}
          {window.ShowProductFilter && <Filter options={productOptions} onOptionSelected={optionChanged} optionType={PRODUCT} selectedValue={productsFilter} />}
          {window.showTopicFilter && <Filter options={topicOptions} onOptionSelected={optionChanged} optionType={TOPIC} selectedValue={topicsFilter} />}
          {window.showInsightSeriesFilter && <Filter options={seriesOptions} onOptionSelected={optionChanged} optionType={SERIES} /> }
          {window.showAuthorFilter && <Filter options={authorOptions} onOptionSelected={optionChanged} optionType={AUTHOR} />}
          {window.showTypeFilter && <Filter options={assetTypeOptions} onOptionSelected={optionChanged} optionType={ASSET} selectedValue={assetTypeFilter} />}
          {window.showEventFilter && <Filter options={eventTypeOptions} onOptionSelected={optionChanged} optionType={EVENT} selectedValue={eventTypeFilter} />}
        </div>}

      {/* <pre>{JSON.stringify(selectedOptions)}</pre> */}

      {data.length > 0 &&
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid_column">
          {data.map(node => <ListItem key={node.Id} {...node} />)}
        </Masonry>}

      {data.length === 0 && !loading && <p>There are no items currently.</p>}

      <div ref={loader}></div>
      {data.length > 0 && window.showFilters && 
      <div className='hidden lg:flex cursor-pointer justify-center mt-4 mb-10' onClick={() => scrollToTop()}>
        <div className='inline-block'>
          <span className='underline'>Scroll to top</span>
        </div>
      </div>}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    data: getFilteredItems(state),
    communityOptions: state.communityOptions,
    productOptions: state.productOptions,
    topicOptions: state.topicOptions,
    assetTypeOptions: state.assetTypeOptions,
    pageSize: state.pageSize,
    selectedOptions: state.selectedOptions,
    communitiesFilter: state.communitiesFilter,
    productsFilter: state.productsFilter,
    topicsFilter: state.topicsFilter,
    assetTypeFilter: state.assetTypeFilter,
    eventTypeFilter: state.eventTypeFilter,
    eventTypeOptions: state.eventTypeOptions,
    textSearch: state.textSearch,
    authorOptions: getAuthorList(state),
    seriesOptions: state.seriesOptions,
    enabledFilters: state.enabledFilters
  }
}

export default connect(mapStateToProps)(App);
