import React from 'react';

export const ImageWrapper = ({
  ImgLgDesktop,
  ImgDesktop,
  ImgTablet,
  ImgMobile,
  ImgAlt,
}) => {
  if (ImgLgDesktop) {
    return (
      <picture>
        <source srcSet={ImgLgDesktop} media="(min-width: 1280px)" />
        <source srcSet={ImgDesktop} media="(min-width: 701px)" />
        <source srcSet={ImgTablet} media="(min-width: 501px)" />
        <source srcSet={ImgMobile} media="(min-width: 320px)" />
        <img src={ImgMobile} alt={ImgAlt} />
      </picture>
    )
  }else{
    return null;
  }
}