import {find, kebabCase, get} from 'lodash';
import { SET_SEARCH, SET_TOPIC } from '../enums';

//'{"SET_COMMUNITY":1135,"SET_PRODUCT":1148}'
const getOptionValue = (optionId, optionKey, allFilterOptions) => {
  
  // let com = find(communityOptions, { Id: parseInt(communitiesFilter, 10) });
  // let top = find(topicOptions, { Id: parseInt(topicsFilter, 10) });
  // let prod = find(productOptions, { Id: parseInt(productsFilter, 10) });
  // let asset = find(assetTypeOptions, { Id: parseInt(assetTypeFilter, 10) });
  try {

    //simply return a key=value string here because its just text
    //rather than an option with an ID
    if (optionKey === SET_SEARCH && allFilterOptions[optionKey].length > 0) {
      return `${optionKey}=${allFilterOptions[optionKey]}`;
    }

    if (optionKey === SET_TOPIC && allFilterOptions[optionKey].length > 0) {
      let topicFilterOption = find(allFilterOptions[optionKey], e => e.Id.toString().includes(optionId.toString()));
      return `${optionKey}=${kebabCase(get(topicFilterOption, 'Value', ''))}`;
    }

    if (allFilterOptions[optionKey]) {
      let filterOption = find(allFilterOptions[optionKey], {Id: optionId});
      
      if (filterOption.Id > 0) {
        return `${optionKey}=${kebabCase(get(filterOption, 'Value', ''))}`;
      }else{
        return `${optionKey}=all`;
      }
    }
    
  } catch (error) {
    console.log('getOptionValue failed', optionId, optionKey, allFilterOptions);
  }
}

export const getLocationHash = (selectedOptions, allFilterOptions, enabledFilters) => {

  let optionKeys = Object.keys(enabledFilters);

  // use _.difference([1, 2, 3, 4, 5], [5, 2, 10]);

  let optionsArray = [];
  for (let index = 0; index < optionKeys.length; index++) {
    const key = optionKeys[index];
    let val = getOptionValue(selectedOptions[key], key, allFilterOptions);
    if (val !== '') {
      optionsArray.push(val);
    }
  }

  //optionKeys.map(key => getOptionValue(selectedOptions[key], key, allFilterOptions));
  
  return optionsArray.join('&').toLowerCase()
  .replaceAll('set_', '')
  .replaceAll('communities-all', 'any');
}