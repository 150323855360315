import { createStore, compose } from 'redux';
import { combineReducers } from 'redux';
import { data } from './reducers/data-items';
import { communitiesFilter, topicsFilter, assetTypeFilter, productsFilter, selectedOptions, eventTypeFilter, textSearch, textSearchResults, authorFilter, enabledFilters, seriesFilter } from './reducers/filters';
import { communityOptions,
  productOptions,
  topicOptions,
  assetTypeOptions, pageSize, eventTypeOptions, seriesOptions } from './reducers/options';


const rootReducer = combineReducers({
    data,
    communitiesFilter,
    topicsFilter,
    assetTypeFilter,
    productsFilter,
    communityOptions,
    productOptions,
    topicOptions,
    assetTypeOptions,
    pageSize,
    selectedOptions,
    eventTypeFilter,
    eventTypeOptions,
    textSearch, 
    textSearchResults,
    authorFilter,
    seriesOptions,
    enabledFilters,
    seriesFilter
});

const store = createStore(
  rootReducer,
  compose(
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
)

export default store;